import { Buffer } from 'buffer';
import repositoryMsgs from 'common/dist/messages/repository';
import { REPO_TYPE, repoTypeToSpeaking } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { BsFileEarmarkCode } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import styles from './styles.module.scss';
import { useModuleByCode } from '../../../../core/api/modules';
import { useUserSummary } from '../../../../core/api/users';
import { useReadme } from '../../../../core/api/workbench/collab';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import ModuleIconFallback from '../../../molecules/module-card/module-icon-fallback/ModuleIconFallback';
import ErrorBoundary from '../../../pages/error-boundary/ErrorBoundary';
import {
  modulesBuildLink,
  modulesEditLink,
  overviewModulesLink,
  repositoryCloneLink2,
} from '../../routes';

export type ModuleDetailsProps = {
  moduleCode: string;
};

const ModuleOverview: FC<ModuleDetailsProps> = ({ moduleCode }) => {
  const intl = useIntl();
  const {
    isInitialLoading: isLoading,
    isError,
    data: module,
  } = useModuleByCode(moduleCode);
  const userHook = useUserSummary(module?.createdBy ?? undefined);
  const {
    isInitialLoading: userLoading,
    isError: userError,
    data: user,
  } = userHook
    ? userHook
    : { isInitialLoading: false, isError: false, data: undefined };

  const codeAvailable = !!module?.repository;
  let group: string, repositoryName: string;
  if (codeAvailable) {
    // createdByUserId + "/" + repoName = repoFullName
    group = module?.repository.createdByUserId;
    repositoryName = module?.repository.repoName;
  }

  const { data: readme, isInitialLoading: readmeLoading } = useReadme(
    group,
    repositoryName,
    !!(group && repositoryName)
  );
  if (isLoading || userLoading || readmeLoading) {
    return (
      <div className={styles.PageContainer}>
        <Busy isBusy />
      </div>
    );
  }

  let content;
  if (readme && readme.content) {
    const encoding: BufferEncoding = readme?.encoding as BufferEncoding;
    content = Buffer.from(readme.content, encoding).toString('utf8');
  }

  function renderReadme() {
    const contentText = readme?.name
      ? 'The README.md is currently empty'
      : 'There is no README.md in your module';

    return content ? (
      <ReactMarkdown
        skipHtml={false}
        rehypePlugins={[rehypeRaw]}
        className={styles.Content}
      >
        {content}
      </ReactMarkdown>
    ) : (
      <div className={styles.Content}>{contentText}</div>
    );
  }

  return (
    <ErrorBoundary>
      <div className={styles.PageContainer}>
        <div className={styles.breadCrumbs}>
          <BreadCrumbs
            backToProps={[
              {
                linkTo: overviewModulesLink(),
                label: intl.formatMessage(repositoryMsgs.backToOverview, {
                  speakingRepoType: repoTypeToSpeaking[REPO_TYPE.MODULE],
                }),
              },
            ]}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.Tile}>
            <div className={styles.body}>
              <div className={styles.iconContainer}>
                {module.moduleAvatar ? (
                  <img
                    className={styles.icon}
                    src={`data:${module.moduleAvatar.imageType};base64, ${module.moduleAvatar.imageData}`}
                    alt={''}
                  />
                ) : (
                  <ModuleIconFallback name={module.name} />
                )}
              </div>
              <div className={styles.textContainer}>
                <span className={styles.title} title={module.name}>
                  {module.name} ({module.code})
                </span>
                <span className={styles.description}>
                  {module.description ? (
                    <LinesEllipsis
                      text={module.description}
                      maxLine={2}
                      ellipsis={'...'}
                      trimRight
                      basedOn={'words'}
                    />
                  ) : (
                    <em>No Description available.</em>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.Buttons}>
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Edit'}
              linkTo={modulesEditLink(moduleCode)}
              disabled={module.isStock}
            />
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Build Module'}
              disabled={!codeAvailable || module.isStock}
              linkTo={
                group &&
                repositoryName &&
                modulesBuildLink(moduleCode, group, repositoryName)
              }
            />
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Clone'}
              disabled={!codeAvailable || module.isStock}
              linkTo={
                group &&
                repositoryName &&
                repositoryCloneLink2(group, repositoryName, { moduleCode })
              }
            />
          </div>
          <div className={styles.Icon}>
            {module.isStock && (
              <img
                src={'/favicons/apple-touch-icon.png'}
                alt={'as'}
                title={'AltaSigma stock module'}
              />
            )}
            {user && (
              <>
                <AvatarIconContainer
                  userId={module.createdBy}
                  showTooltip={true}
                />
              </>
            )}
          </div>
          <div className={styles.ReadmeBlock}>
            <div className={styles.Header}>
              <BsFileEarmarkCode size={26} />
              <h5>README</h5>
            </div>
            {!codeAvailable ? (
              <div className={styles.Content}>
                <a
                  href='/doc/docs/concepts/modelManagement/archetypes' //TODO CM: Add the correct link here
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Show Documentation for {module.name}
                </a>
              </div>
            ) : (
              renderReadme()
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ModuleOverview;
